// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./ybonrmj7z-0.js";

const cycleOrder = ["YSwa56grE", "jiu1FRWGg", "gGf1mCzlP"];

const variantClassNames = {gGf1mCzlP: "framer-v-1b14xer", jiu1FRWGg: "framer-v-1j0v8b", YSwa56grE: "framer-v-h69jo8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "YSwa56grE", "Variant 2": "jiu1FRWGg", "Variant 3": "gGf1mCzlP"}

const getProps = ({_vod, height, id, width, ...props}) => { return {...props, UWaVMIaqq: _vod ?? props.UWaVMIaqq ?? "Úvod", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YSwa56grE"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;_vod?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UWaVMIaqq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YSwa56grE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter8n23to = activeVariantCallback(async (...args) => {
setVariant("jiu1FRWGg")
})

const onTap57r8y8 = activeVariantCallback(async (...args) => {
setVariant("gGf1mCzlP")
})

const onMouseLeave57r8y8 = activeVariantCallback(async (...args) => {
setVariant("gGf1mCzlP")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8bmUF", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({jiu1FRWGg: {href: {webPageId: "qcWC5PmGM"}}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-h69jo8", className)} framer-1jp715d`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"YSwa56grE"} onMouseEnter={onMouseEnter8n23to} ref={ref} style={{...style}} {...addPropertyOverrides({gGf1mCzlP: {"data-framer-name": "Variant 3"}, jiu1FRWGg: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave57r8y8, onTap: onTap57r8y8}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-weight": "600", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Úvod</motion.p></React.Fragment>} className={"framer-15d02zs"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"b3qov_rbf"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", textShadow: "none"}} text={UWaVMIaqq} transformTemplate={transformTemplate} variants={{jiu1FRWGg: {textShadow: "0px 0px 7px rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8bmUF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8bmUF .framer-1jp715d { display: block; }", ".framer-8bmUF .framer-h69jo8 { height: 24px; overflow: visible; position: relative; width: 40px; }", ".framer-8bmUF .framer-15d02zs { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-8bmUF.framer-v-1j0v8b .framer-h69jo8 { cursor: pointer; text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jiu1FRWGg":{"layout":["fixed","fixed"]},"gGf1mCzlP":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UWaVMIaqq":"_vod"}
 * @framerImmutableVariables false
 */
const Framerybonrmj7z: React.ComponentType<Props> = withCSS(Component, css, "framer-8bmUF") as typeof Component;
export default Framerybonrmj7z;

Framerybonrmj7z.displayName = "Uvod component";

Framerybonrmj7z.defaultProps = {height: 24, width: 40};

addPropertyControls(Framerybonrmj7z, {variant: {options: ["YSwa56grE", "jiu1FRWGg", "gGf1mCzlP"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, UWaVMIaqq: {defaultValue: "Úvod", displayTextArea: false, placeholder: "", title: "Úvod", type: ControlType.String}} as any)

addFonts(Framerybonrmj7z, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/ybonrmj7z:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf", weight: "600"}])